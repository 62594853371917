import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../alerts/Danger';
import RequiredAsterisk from '../../form/required_asterisk';
import Wysiwyg from '../../form/wysiwyg';
import Select from '../../form/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTwitter,
    faTiktok,
    faDiscord,
    faSnapchat,
    faReddit,
    faYoutube,
    faPatreon,
    faSteam,
    faKickstarter,
    faTelegram,
    faPinterest,
    faTwitch,
} from '@fortawesome/free-brands-svg-icons';
import Navigate from '../../../../../util/Navigate';
import SchedulerSocialForm from './schedule_social_form';

const SchedulerForm = ({
    schedulerData,
    setSchedulerData,
    setCountries,
    setGenders,
    setTypes,
    communities,
    isEditMode,
    errors,
}) => {
    const [platforms, setPlatforms] = useState([
        { name: 'Facebook', icon: faFacebook, field: 'facebook' },
        { name: 'Instagram', icon: faInstagram, field: 'instagram' },
        { name: 'Twitter', icon: faTwitter, field: 'twitter' },
        { name: 'TikTok', icon: faTiktok, field: 'tiktok' },
        { name: 'Discord', icon: faDiscord, field: 'discord' },
        { name: 'Reddit', icon: faReddit, field: 'reddit' },
        { name: 'YouTube', icon: faYoutube, field: 'youtube' },
        { name: 'Telegram', icon: faTelegram, field: 'telegram' },
    ]);

    const [selectedTypes, setSelectedTypes] = useState(schedulerData.types || []);
    const [availableTypes, setAvailableTypes] = useState([]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        let updatedValue = type === 'checkbox' ? checked : value;

        setSchedulerData((prevData) => ({
            ...prevData,
            [name]: updatedValue,
        }));
    };

    const handleWysiwigInputChange = (name, value) => {
        setSchedulerData((schedulerData) => ({ ...schedulerData, [name]: value }));
    };

    const handleWysiwygChange = (name, value) => {
        setSchedulerData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTypesChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(options[i].value);
            }
        }
        setSelectedTypes(selected);
        setSchedulerData((prevData) => ({
            ...prevData,
            types: selected,
        }));
    };

    useEffect(() => {
        Glitch.api.Utility.listTypes()
            .then((response) => {
                setAvailableTypes(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch types:', error);
            });
    }, []);

    const connectPlatform = (platform) => {

        const params = '?scheduler_id=' + schedulerData?.id;

        let oauthUrl = null;

        if (platform.field === 'facebook') {
            oauthUrl = Navigate.authFacebook() + params;
        } else if (platform.field === 'tiktok') {
            oauthUrl = Navigate.authTikTok() + params;
        } else if (platform.field === 'youtube') {
            oauthUrl = Navigate.authGoogle() + params;
        } else if (platform.field === 'google') {
            oauthUrl = Navigate.authGoogle() + params;
        } else if (platform.field === 'twitter') {
            oauthUrl = Navigate.authTwitter() + params;
        } else if (platform.field === 'instagram') {
            oauthUrl = Navigate.authFacebook() + params;
        } else if (platform.field === 'reddit') {
            oauthUrl = Navigate.authReddit() + params;
        }

        if (oauthUrl) {
            window.open(oauthUrl, '_blank');
        } else {
            alert(platform + " not found");
        }
    };

    return (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-header bg-primary text-white">
                    <h3>
                        <i className="fas fa-calendar-alt mr-2"></i> Scheduler Information
                    </h3>
                </div>
                <div className="card-body">
                    <p className="lead">
                        Configure your social media scheduler to automatically promote your game on various platforms.
                    </p>
                    <hr />
                    <div className="row">
                        {createInput(
                            'Scheduler Name',
                            'name',
                            schedulerData.name,
                            handleInputChange,
                            'text',
                            'fas fa-signature',
                            errors,
                            'Enter a name for this scheduler.',
                            true
                        )}
                        <div className="col">
                            <div className="form-group mb-3">
                                <label htmlFor="pull_from_community">
                                    <i className="fas fa-users mr-2"></i> &nbsp;Pull Content From Community
                                </label>
                                <p className="small">
                                    If enabled, the scheduler will pull content from your game's community to include in posts.
                                </p>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="pull_from_community"
                                        name="pull_from_community"
                                        checked={schedulerData.pull_from_community || false}
                                        onChange={handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="pull_from_community">
                                        Enable
                                    </label>
                                </div>
                                {errors && errors['pull_from_community'] && errors['pull_from_community'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        </div>
                    </div>

                    {createTextarea(
                        'Targeting Information',
                        'targeting_information',
                        schedulerData.targeting_information,
                        handleWysiwygChange,
                        errors,
                        'Provide any specific information about the target audience for your game.'
                    )}

                    <div className="row">
                        {createInput(
                            'Hashtags',
                            'hashtags',
                            schedulerData.hashtags && Array.isArray(schedulerData.hashtags) ? schedulerData.hashtags.join(', ') : schedulerData.hashtags,
                            handleInputChange,
                            'text',
                            'fas fa-hashtag',
                            errors,
                            'Enter hashtags separated by commas.'
                        )}
                        {createInput(
                            'Minimum Age',
                            'age_min',
                            schedulerData.age_min,
                            handleInputChange,
                            'number',
                            'fas fa-user',
                            errors,
                            'Set the minimum age of your target audience.'
                        )}
                        {createInput(
                            'Maximum Age',
                            'age_max',
                            schedulerData.age_max,
                            handleInputChange,
                            'number',
                            'fas fa-user',
                            errors,
                            'Set the maximum age of your target audience.'
                        )}
                    </div>

                    <div className="row">
                        {createInput(
                            'Total Posts per Week',
                            'posts_per_week',
                            schedulerData.posts_per_week,
                            handleInputChange,
                            'number',
                            'fas fa-calendar-week',
                            errors,
                            'Set the total number of posts to be made every week across all platforms. Each platform will get this number of posts each week.',
                            true
                        )}
                        {createInput(
                            'Email Reminders per Week',
                            'email_reminders_per_week',
                            schedulerData.email_reminders_per_week,
                            handleInputChange,
                            'number',
                            'fas fa-envelope',
                            errors,
                            'Set the number of email reminders to send per week, reminding you/your team to leave notes.',
                            true
                        )}
                    </div>

                    {isEditMode ?

                        <SchedulerSocialForm schedulerData={schedulerData} setSchedulerData={setSchedulerData} />
                        : ''}

                </div>
            </div>
        </div>
    );

    function createInput(
        label,
        name,
        value,
        handleChange,
        type = 'text',
        icon,
        errors,
        description = '',
        required = false
    ) {
        return (
            <div className="col-md-6">
                <div className="form-group mb-3">
                    <label htmlFor={name}>
                        <i className={`${icon} mr-2`}></i> &nbsp;{label} {required ? <RequiredAsterisk /> : ''}
                    </label>
                    <p className="small">{description}</p>
                    <input
                        type={type}
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                    />
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }

    function createTextarea(label, name, value, handleChange, errors, description, required = false) {
        return (
            <div className="col-md-12">
                <div className="mb-3">
                    <label htmlFor={name}>
                        {label} {required && <RequiredAsterisk />}
                    </label>
                    <p className="small">{description}</p>
                    <Wysiwyg
                        children={value || ''}
                        name={name}
                        id={name}
                        onChange={(value) => {
                            handleWysiwigInputChange(name, value);
                        }}
                    />
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }
};

export default SchedulerForm;
